require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

window.jQuery = $
window.$ = $

import "lodash";

import axios from 'axios';

// import "bootstrap";
import "jquery-ui";

require("local-time").start()

import '../theme/plugins/bootstrap.bundle.min.js';
import '../theme/plugins/bootstrap.min.css';
import '../theme/plugins/slick.min.js';
import '../theme/plugins/slick.css';
// import '../javascript/county';
import '../theme/plugins/jquery.tinyMap.js'
import '../theme/plugins/jquery.qrcode.min.js'

// import 'data-confirm-modal'

import '../javascript/noty_front';
// import '../javascript/bootstrap';
import '../javascript/select2';
import '../javascript/flatpickr'
import '../javascript/fancybox';

import '../javascript/home'

// import "../stylesheets/home";
import "../stylesheets/home.css";
import "@fortawesome/fontawesome-free/css/all"

document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll("action-text-attachment").forEach(function(i){
    let src = i.getAttribute("url");
    if(src.indexOf('img.youtube.com/vi/') >= 0){
      let youtube_id = src.split("img.youtube.com/vi/").pop().split("/0.jpg")[0];
      let embed = new DOMParser().parseFromString(`<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, 'text/html').body.firstChild;
      i.replaceWith(embed);
    };
  })
});
