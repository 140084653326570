import flatpickr from 'flatpickr'
import "flatpickr/dist/l10n/zh-tw"

document.addEventListener('DOMContentLoaded', function(){
	flatpickr.localize(flatpickr.l10ns.zh_tw);

	flatpickr(".datepicker", {});

	flatpickr(".datetimepicker", {
		enableTime: true,
		time_24hr: true
	})

	$(".datetimepicker").each(function(){
		var input = $(this)
		input.css('width','calc(100% - 80px)')
		var remove_btn = $(`<button type="button" class="btn btn-sm btn-warning" style="position: absolute;right: 20px;top: 0;">清除</button>`)
		remove_btn.insertAfter(input)
		remove_btn.on('click',function(){
			input.val("")
		})
	})

	flatpickr(".daterange", {
		mode: "range",
		maxDate: "today",
		dateFormat: "Y/m/d",
		locale: {
			rangeSeparator: '-'
		}
	});


	flatpickr(".daterangepicker", {
		mode: "range",
		maxDate: "today",
		dateFormat: "Y/m/d",
		locale: {
			rangeSeparator: '-'
		}
	});


})
